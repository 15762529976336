import React, { Component } from 'react';
import '../assets/css/WareRecent.css';
import axios from 'axios';
import moment from 'moment';

class WareRecent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dltissue: '',
            dltball1: '',
            dltball2: '',
            dltball3: '',
            dltball4: '',
            dltball5: '',
            dltball6: '',
            dltball7: '',
            dltresultTime: '',
            qxcissue: '',
            qxcball1: '',
            qxcball2: '',
            qxcball3: '',
            qxcball4: '',
            qxcball5: '',
            qxcball6: '',
            qxcball7: '',
            qxcresultTime: '',
            pl3issue: '',
            pl3ball1: '',
            pl3ball2: '',
            pl3ball3: '',
            pl3resultTime: '',
            pl5issue: '',
            pl5ball1: '',
            pl5ball2: '',
            pl5ball3: '',
            pl5ball4: '',
            pl5ball5: '',
            pl5resultTime: ''
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        var url = 'https://api.wwsrkj.com/get';
        var data = {
            "[]": {
              "WareRecent": {
                "@order":"lottery_id"
              }
            }
          }
        axios.post(url,data)
            .then((response) => {
                // handle success
                //console.log(response.data);
                //alert(JSON.stringify(response.data['WareRecent[]'][3].result))
                var dltresult = response.data[3].result.replace(/\[/g, "").replace(/\]/g, "").replace(/\//g, ",").split(',');
                var qxcresult = response.data[2].result.replace(/\[/g, "").replace(/\]/g, "").replace(/\//g, ",").split(',');
                var pl3result = response.data[0].result.replace(/\[/g, "").replace(/\]/g, "").replace(/\//g, ",").split(',');
                var pl5result = response.data[1].result.replace(/\[/g, "").replace(/\]/g, "").replace(/\//g, ",").split(',');
                this.setState({
                    dltissue: response.data[3].issue,
                    dltball1: dltresult[0],
                    dltball2: dltresult[1],
                    dltball3: dltresult[2],
                    dltball4: dltresult[3],
                    dltball5: dltresult[4],
                    dltball6: dltresult[5],
                    dltball7: dltresult[6],
                    dltresultTime: response.data[3].result_time,
                    qxcissue: response.data[2].issue,
                    qxcball1: qxcresult[0],
                    qxcball2: qxcresult[1],
                    qxcball3: qxcresult[2],
                    qxcball4: qxcresult[3],
                    qxcball5: qxcresult[4],
                    qxcball6: qxcresult[5],
                    qxcball7: qxcresult[6],
                    qxcresultTime: response.data[2].result_time,
                    pl3issue: response.data[0].issue,
                    pl3ball1: pl3result[0],
                    pl3ball2: pl3result[1],
                    pl3ball3: pl3result[2],
                    pl3resultTime: response.data[0].result_time,
                    pl5issue: response.data[1].issue,
                    pl5ball1: pl5result[0],
                    pl5ball2: pl5result[1],
                    pl5ball3: pl5result[2],
                    pl5ball4: pl5result[3],
                    pl5ball5: pl5result[4],
                    pl5resultTime: response.data[1].result_time
                })
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }

    render() {
        return (
            <div>
                <div className="draw_ticai">
                    <span>体彩最新开奖</span>
                </div>
                <div className="draw_red_hr"></div>
                <table className="draw_tc">
                    <tbody>
                        <tr className="drawonetr" >
                            <td style={{ "width": '14%', "paddingLeft": '8px' }}>彩种</td>
                            <td style={{ "width": '11%' }}>期次</td>
                            <td style={{ "width": '56%' }}>开奖号码</td>
                            <td style={{ "width": '18%' }}>开奖时间</td>
                        </tr>
                        <tr className="drawtr">
                            <td style={{ "color": '#237EA9' }}>大乐透</td>
                            <td>{this.state.dltissue}</td>
                            <td>
                                <span className="draw_red">{this.state.dltball1}</span>
                                <span className="draw_red">{this.state.dltball2}</span>
                                <span className="draw_red">{this.state.dltball3}</span>
                                <span className="draw_red">{this.state.dltball4}</span>
                                <span className="draw_red">{this.state.dltball5}</span>
                                <span className="draw_blue">{this.state.dltball6}</span>
                                <span className="draw_blue">{this.state.dltball7}</span>
                            </td>
                            <td>{moment(this.state.dltresultTime).format('MM-DD')}</td>
                        </tr>
                        <tr style={{ "height": '1px' }}>
                            <td colSpan="4" className="hrtd"></td>
                        </tr>
                        <tr className="drawonetr">
                            <td style={{ "color": '#237EA9' }}>七星彩</td>
                            <td>{this.state.qxcissue}</td>
                            <td>
                                <span className="draw_red">{this.state.qxcball1}</span>
                                <span className="draw_red">{this.state.qxcball2}</span>
                                <span className="draw_red">{this.state.qxcball3}</span>
                                <span className="draw_red">{this.state.qxcball4}</span>
                                <span className="draw_red">{this.state.qxcball5}</span>
                                <span className="draw_red">{this.state.qxcball6}</span>
                                <span className="draw_red">{this.state.qxcball7}</span>
                            </td>
                            <td>{moment(this.state.qxcresultTime).format('MM-DD')}</td>
                        </tr>
                        <tr style={{ "height": '1px' }}>
                            <td colSpan="4" className="hrtd"></td>
                        </tr>
                        <tr className="drawtr">
                            <td style={{ "color": '#237EA9' }}>排列三</td>
                            <td>{this.state.pl3issue}</td>
                            <td>
                                <span className="draw_red">{this.state.pl3ball1}</span>
                                <span className="draw_red">{this.state.pl3ball2}</span>
                                <span className="draw_red">{this.state.pl3ball3}</span>
                            </td>
                            <td>{moment(this.state.pl3resultTime).format('MM-DD')}</td>
                        </tr>
                        <tr style={{ "height": '1px' }}>
                            <td colSpan="4" className="hrtd"></td>
                        </tr>
                        <tr className="drawonetr">
                            <td style={{ "color": '#237EA9' }}>排列五</td>
                            <td>{this.state.pl5issue}</td>
                            <td>
                                <span className="draw_red">{this.state.pl5ball1}</span>
                                <span className="draw_red">{this.state.pl5ball2}</span>
                                <span className="draw_red">{this.state.pl5ball3}</span>
                                <span className="draw_red">{this.state.pl5ball4}</span>
                                <span className="draw_red">{this.state.pl5ball5}</span>
                            </td>
                            <td>{moment(this.state.pl5resultTime).format('MM-DD')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default WareRecent;