import React, { Component } from 'react';

import 'rsuite/styles/index.less';
import { Table } from 'rsuite';
import axios from 'axios';

const { Column, HeaderCell, Cell, Pagination } = Table;

const Red = {
    background: `url(${require('../assets/images/red.png')}) no-repeat`,
    width: "28px",
    height: "28px",
    color: "white",
    margin: "10px auto",
    padding: "4px 4px 2px 2px"
}
const Blue = {
    background: `url(${require('../assets/images/blue.png')}) no-repeat`,
    width: "28px",
    height: "28px",
    color: "white",
    margin: "10px auto",
    padding: "4px 4px 2px 2px"
}
const ImageCell = ({ rowData, dataKey, ...props }) => (
    <Cell {...props} style={rowData[dataKey] == "0" ? dataKey.indexOf("r") == 0 ? Red : Blue : ""}>
        {rowData[dataKey] == "0" ? dataKey.replace("r", "").replace("b", "") : rowData[dataKey]}
    </Cell>
);
class DltDataChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dltDataCharts: []
        };
    }

    componentDidMount() {
        this.getData();
    }



    getData = () => {
        var url = 'https://api.wwsrkj.com/get';
        var data = {
            "[]": {
                "DltDatachart": {
                    "@order": "issue-"
                },
                "page": 0,
                "count": 30
            }
        }
        axios.post(url,data)
            .then((res) => {
                // handle success
                //console.log(response);
                //alert(JSON.stringify(res))
                this.setState({
                    dltDataCharts: res.data
                })
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }

    render() {
        return (
            <div>
                <Table
                    virtualized
                    height={750}
                    data={this.state.dltDataCharts}
                >
                    <Column width={70} align="center" fixed>
                        <HeaderCell>期号</HeaderCell>
                        <Cell dataKey="issue" />
                    </Column>

                    <Column width={38} align="center">
                        <HeaderCell>1</HeaderCell>
                        <ImageCell dataKey="r1" />
                    </Column>

                    <Column width={38} align="center">
                        <HeaderCell>2</HeaderCell>
                        <ImageCell dataKey="r2" />
                    </Column>

                    <Column width={38} align="center">
                        <HeaderCell>3</HeaderCell>
                        <ImageCell dataKey="r3" />
                    </Column>

                    <Column width={38} align="center">
                        <HeaderCell>4</HeaderCell>
                        <ImageCell dataKey="r4" />
                    </Column>

                    <Column width={38} align="center">
                        <HeaderCell>5</HeaderCell>
                        <ImageCell dataKey="r5" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>6</HeaderCell>
                        <ImageCell dataKey="r6" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>7</HeaderCell>
                        <ImageCell dataKey="r7" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>8</HeaderCell>
                        <ImageCell dataKey="r8" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>9</HeaderCell>
                        <ImageCell dataKey="r9" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>10</HeaderCell>
                        <ImageCell dataKey="r10" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>11</HeaderCell>
                        <ImageCell dataKey="r11" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>12</HeaderCell>
                        <ImageCell dataKey="r12" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>13</HeaderCell>
                        <ImageCell dataKey="r13" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>14</HeaderCell>
                        <ImageCell dataKey="r14" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>15</HeaderCell>
                        <ImageCell dataKey="r15" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>16</HeaderCell>
                        <ImageCell dataKey="r16" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>17</HeaderCell>
                        <ImageCell dataKey="r17" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>18</HeaderCell>
                        <ImageCell dataKey="r18" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>19</HeaderCell>
                        <ImageCell dataKey="r19" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>20</HeaderCell>
                        <ImageCell dataKey="r20" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>21</HeaderCell>
                        <ImageCell dataKey="r21" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>22</HeaderCell>
                        <ImageCell dataKey="r22" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>23</HeaderCell>
                        <ImageCell dataKey="r23" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>24</HeaderCell>
                        <ImageCell dataKey="r24" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>25</HeaderCell>
                        <ImageCell dataKey="r25" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>26</HeaderCell>
                        <ImageCell dataKey="r26" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>27</HeaderCell>
                        <ImageCell dataKey="r27" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>28</HeaderCell>
                        <ImageCell dataKey="r28" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>29</HeaderCell>
                        <ImageCell dataKey="r29" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>30</HeaderCell>
                        <ImageCell dataKey="r30" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>31</HeaderCell>
                        <ImageCell dataKey="r31" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>32</HeaderCell>
                        <ImageCell dataKey="r32" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>33</HeaderCell>
                        <ImageCell dataKey="r33" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>34</HeaderCell>
                        <ImageCell dataKey="r34" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>35</HeaderCell>
                        <ImageCell dataKey="r35" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>1</HeaderCell>
                        <ImageCell dataKey="b1" />
                    </Column>

                    <Column width={38} align="center">
                        <HeaderCell>2</HeaderCell>
                        <ImageCell dataKey="b2" />
                    </Column>

                    <Column width={38} align="center">
                        <HeaderCell>3</HeaderCell>
                        <ImageCell dataKey="b3" />
                    </Column>

                    <Column width={38} align="center">
                        <HeaderCell>4</HeaderCell>
                        <ImageCell dataKey="b4" />
                    </Column>

                    <Column width={38} align="center">
                        <HeaderCell>5</HeaderCell>
                        <ImageCell dataKey="b5" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>6</HeaderCell>
                        <ImageCell dataKey="b6" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>7</HeaderCell>
                        <ImageCell dataKey="b7" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>8</HeaderCell>
                        <ImageCell dataKey="b8" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>9</HeaderCell>
                        <ImageCell dataKey="b9" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>10</HeaderCell>
                        <ImageCell dataKey="b10" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>11</HeaderCell>
                        <ImageCell dataKey="b11" />
                    </Column>
                    <Column width={38} align="center">
                        <HeaderCell>12</HeaderCell>
                        <ImageCell dataKey="b12" />
                    </Column>
                </Table>
            </div>
        );
    }
}

export default DltDataChart;