import React from 'react';
import { Nav, Icon } from 'rsuite';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import WareRecent from './components/WareRecent';
import DltDataChart from './components/DltDataChart';

function App() {
  return (
    <Router>
      <Nav>
        <Nav.Item eventKey="home" icon={<Icon icon="home" />} href="/">首页</Nav.Item>
        <Nav.Item eventKey="chart" href="/DltDataChart">大乐透走势图</Nav.Item>
      </Nav>

      <Route exact path="/" component={WareRecent} />
      <Route path="/DltDataChart" component={DltDataChart} />
    </Router>
  );
}

export default App;
